<template>
  <div class="filter_selec_box after slid_up">
    <swiper class="chocie_tag" :options="swiperOptions">
      <swiper-slide v-for="item in items" :key="item.key">
        <button type="button" @click="$emit('click', item)">
          {{ item.label }}
        </button>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: ['items'],
  setup() {
    return {
      swiperOptions: {
        slidesPerView: 'auto',
        watchOverflow: true,
        observer: true,
        observeParents: true,
      },
    };
  },
};
</script>

<style>
.filter_selec_box {
  overflow: hidden;
}
</style>
