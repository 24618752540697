import { sinkerAPI as axios } from '@/plugins/axios';

function splitCategoryIdAndLabel(category) {
  const result = [];
  if (category && category.length > 0) {
    for (const idAndLabel of category) {
      const keyword = idAndLabel.split('#')[0];
      console.log(keyword);
      result.push(keyword);
    }
  }
  console.log(result);
  return result;
}

export default {
  searchKeywordRanking() {
    let result = axios().post('search/keyword_ranking');
    return result;
  },
  searchKeyword(keyword) {
    const params = {
      index: ['provider', 'cmall_coupon'],
      keyword: keyword,
      sort: [],
      from: 0,
      size: 10,
    };
    let result = axios().post('search/keyword_search', params);
    return result;
  },
  searchItem(params) {
    const default_source = [];
    const default_from = 0;
    const default_size = 10;
    let sort = null;
    let source = [];
    let range = [];
    const {
      limit,
      orderBy,
      page,
      age,
      category1,
      category2,
      category3,
      color,
      concept,
      price,
      soldOut,
      type,
      den_id, // 브랜드 검색용
      keyword, // 키워드 검색용
    } = params;
    let category = [];
    category = category.concat(splitCategoryIdAndLabel(category1));
    category = category.concat(splitCategoryIdAndLabel(category2));
    category = category.concat(splitCategoryIdAndLabel(category3));
    console.log(
      'age, category, color, concept, price, soldOut, type, den_id, keyword'
    );
    console.log(
      age,
      category,
      color,
      concept,
      price,
      soldOut,
      type,
      den_id,
      keyword
    );
    if (keyword) {
      source.push({
        fields: ['cit_name'],
        query: keyword,
        weight: 1,
      });
    }
    if (den_id) {
      source.push({
        fields: ['den_id'],
        query: den_id,
        weight: 1,
      });
    }
    if (category && category.length > 0) {
      source.push({
        fields: ['cca_id', 'cca_id2', 'cca_id3'],
        query: category.join(' OR ').replace('/', '\\/'),
        weight: 1,
      });
    }
    if (color && color.length > 0) {
      source.push({
        fields: ['is_color'],
        query: color.join(' OR ').replace('/', '\\/'),
        weight: 1,
      });
    }
    if (concept && concept.length > 0) {
      source.push({
        fields: ['is_concept'],
        query: concept.join(' OR ').replace('/', '\\/'),
        weight: 1,
      });
    }
    if (type === 'm') {
      source.push({
        fields: ['is_sex'],
        query: 'm OR u',
        weight: 1,
      });
    } else {
      source.push({
        fields: ['is_sex'],
        query: 'w OR u',
        weight: 1,
      });
    }
    if (soldOut === true) {
      source.push({
        fields: ['is_order'],
        query: 'y OR n',
        weight: 1,
      });
    } else {
      source.push({
        fields: ['is_order'],
        query: 'y',
        weight: 1,
      });
    }
    if (price && price.length === 2) {
      range.push({
        field: 'cit_sale_price',
        gte: price[0],
        lte: price[1],
      });
    }
    switch (orderBy) {
      case 'sale':
        sort = [{ ratio: 'desc' }, { upd_dtm: 'desc' }, { new_dtm: 'desc' }];
        break;
      case 'new_dtm':
        sort = [{ new_dtm: 'desc' }, { upd_dtm: 'desc' }];
        break;
      case 'upd_dtm':
        sort = [{ upd_dtm: 'desc' }, { new_dtm: 'desc' }];
        break;
      case 'cit_sale_price_desc':
        sort = [
          { cit_sale_price: 'desc' },
          { upd_dtm: 'desc' },
          { new_dtm: 'desc' },
        ];
        break;
      case 'cit_sale_price_asc':
        sort = [
          { cit_sale_price: 'asc' },
          { upd_dtm: 'desc' },
          { new_dtm: 'desc' },
        ];
        break;
      default:
        // console.log('TODO dev');
        // TODO: 추천순 추가개발
        // sort = null;
        // source = [];
        // range = [];
        sort = null;
        break;
    }
    // 삭제아닌 상품만
    source.push({
      fields: ['is_delete'],
      query: 'n',
      weight: 1,
    });
    // 진열중인 상품만
    source.push({
      fields: ['is_list'],
      query: 'y',
      weight: 1,
    });
    source.push({
      fields: ['is_nation'],
      query: 'ko_KR',
      weight: 1,
    });
    const body = {
      index: 'cmall_item',
      source: source ? source : default_source,
      range: range ? range : [],
      sort: sort,
      from: page ? page * limit : default_from,
      size: limit ? limit : default_size,
    };
    console.log('search body');
    console.log(body);
    return axios().post('search/cmall_item/v2', body);
  },
  searchBrand(params) {
    const default_source = [];
    const default_from = 0;
    const default_size = 10;
    let sort = null;
    let source = [];
    let range = [];
    console.log(params);
    const { limit, page, age, category1, concept, style, newly, rising } =
      params;
    console.log('limit, page, age, category1, concept, style, newly, rising');
    console.log(limit, page, age, category1, concept, style, newly, rising);
    if (age && age.length > 0) {
      source.push({
        fields: ['age'],
        query: age.join(' OR ').replace('/', '\\/'),
        weight: 1,
      });
    }
    if (category1 && category1.length > 0) {
      source.push({
        fields: ['provider_category'],
        query: category1.join(' OR ').replace('/', '\\/'),
        weight: 1,
      });
    }
    if (concept && concept.length > 0) {
      source.push({
        fields: ['provider_concept1', 'provider_concept2'],
        query: concept.join(' OR ').replace('/', '\\/'),
        weight: 1,
      });
    }
    if (style && style.length > 0) {
      source.push({
        fields: ['provider_style1', 'provider_style2'],
        query: style.join(' OR ').replace('/', '\\/'),
        weight: 1,
      });
    }
    if (newly) {
      source.push({
        fields: ['new'],
        query: newly,
        weight: 1,
      });
    }
    if (rising) {
      source.push({
        fields: ['rising'],
        query: rising,
        weight: 1,
      });
    }
    const body = {
      index: 'provider',
      source: source ? source : default_source,
      range: range ? range : [],
      sort: sort,
      from: page ? page * limit : default_from,
      size: limit ? limit : default_size,
    };
    console.log('search body');
    console.log(body);
    return axios().post('search/provider', body);
  },
};
